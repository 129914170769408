import type { ReactNode } from 'react';
import classes from './order.module.scss';
import { formatNumberPrice } from '~/shared/lib/helpers';
import { useStore } from '~/shared/hooks/useStore';
import { useTranslation } from 'react-i18next';

interface IOrderTotalProps {
    button?: ReactNode;
}

const OrderTotal = ({
    button,
}: IOrderTotalProps) => {
    const { COrder } = useStore();
    const { order_info } = COrder.order;
    const { payments_sum } = COrder.order.order_payments;
    const { t } = useTranslation('components');

    return (
        <ul className={`${classes.list} ${classes.total} ${button ? classes.withButton : ''}`}>
            { order_info.count_of_services ? (
                <li>
                    <span className={classes.key}>
                        {t('order.total.serviceAmount')}
                    </span>
                    <span className={classes.value}>
                        {t('order.total.countService', {
                            count: order_info.count_of_services
                        })}
                    </span>
                </li>
            ) : null }
            <li>
                <span className={classes.key}>
                    {t('order.total.sumOrder')}
                </span>
                <span className={classes.value}>
                    {formatNumberPrice(Number(order_info.order_sum.toFixed(2)))}
                </span>
            </li>
            <li className={classes.withSub}>
                <ul className={classes.sub}>
                    <li>
                        <span>
                            {t('order.total.bonusPayment')}
                        </span>{' '}
                        <span>
                            {formatNumberPrice(payments_sum.sum_bonus)}
                        </span>
                    </li>
                    <li>
                        <span>
                            {t('order.total.cardPayment')}
                        </span>{' '}
                        <span>
                            {formatNumberPrice(payments_sum.sum_card)}
                        </span>
                    </li>
                    <li>
                        <span>
                            {t('order.total.cashPayment')}
                        </span>{' '}
                        <span>
                            {formatNumberPrice(payments_sum.sum_cash)}
                        </span>
                    </li>
                    <li>
                        <span>
                            {t('order.total.depositPayment')}</span>{' '}
                        <span>
                            {formatNumberPrice(payments_sum.sum_deposit)}
                        </span>
                    </li>
                </ul>
                <span className={classes.key}>
                    {t('order.total.sumAmount')}
                </span>
                <span className={classes.value}>
                    {formatNumberPrice(payments_sum.all_sum)}
                </span>
            </li>
            <li>
                <span className={classes.key}>
                    <b>
                        {t('order.total.needToPay')}
                    </b>
                </span>
                <span className={classes.value}>
                    <b>
                        {formatNumberPrice(Number(order_info.need_to_pay.toFixed(2)))}
                    </b>
                </span>
            </li>
            {button && (
                <li className={classes.button}>
                    {button}
                </li>
            )}
        </ul>
    );
};

export default OrderTotal;
