import classes from './payment.module.scss';
import { formatNumberPrice } from '~/shared/lib/helpers';
import { useStore } from '~/shared/hooks/useStore';
import { observer } from 'mobx-react';
import { useCardPayment } from './hooks/useCardPayment';
import { useBonusPayment } from './hooks/useBonusPayment';
import { useDepositPayment } from './hooks/useDepositPayment';
import { useTranslation } from 'react-i18next';
import SPB from '~/assets/images/spb.svg';
import { useSBPPayment } from './hooks/useSBPPayment';
import { PaymentButton } from '@components/PaymentButton';
import { usePaymentSum } from './hooks/usePaymentSum';

interface IPaymentProps {
    orderNumber: number | string;
    orderSum: number;
    deposit: number;
    bonus: number;
    orderDebt: number;
}

const Payment = ({
    orderNumber,
    orderSum,
    deposit,
    bonus,
    orderDebt,
}: IPaymentProps) => {
    const { CLinks, CClientInfo, CCompanyInfo, CSettings, COrder  } = useStore();
    const { cloud_payment, qr, bonus_payment, deposit_payment, general_pay } = CLinks.links;
    const { order_info } = COrder.order;
    const { company_id } = CCompanyInfo.companyInfo;
    const { contr_id } = CClientInfo.clientInfo;
    const isDemoMode = (company_id === 118);

    //Array of companies to hide bonus and\or deposit payments
    const hideBonusCompanies = [67];
    const hideDepositCompanies = [67];

    const paymentMethod = CSettings.settings.payment_method || 'all'; // Получаем значение payment_method
    const { t } = useTranslation('components');
    const { paymentSum, maxSum, minSum, showInput, onChangeSum, onBlur } = usePaymentSum();
    const [cardPayment, isLoadingCard] = useCardPayment(contr_id, cloud_payment, paymentSum);
    const [bonusPayment, isLoadingBonus] = useBonusPayment(bonus_payment);
    const [depositPayment, isLoadingDeposit] = useDepositPayment(deposit_payment);
    const { SBPPay, isLoadingCard: isLoadingSBPCard, isLoadingQR: isLoadingSBPQR  } = useSBPPayment(qr, paymentSum);

    return (
        <div className={classes.box}>
            <h1 className={classes.title}>
                {t('payments.information.title.payment')} <br /> {t('payments.information.title.order')}
            </h1>

            <div className={classes.sum}>
                <span>
                    <span className={classes.sumDesktopText}>
                        {t('payments.information.sum.orderSum', {
                            orderNumber
                        })}
                    </span>
                    <span className={classes.sumMobileText}>
                        {t('payments.information.sum.orderTotal')}
                    </span>
                </span>
                <span className={classes.key}>
                    {formatNumberPrice(orderSum)}
                </span>
            </div>
            { showInput ? (
                <form className={classes.option} onSubmit={(e) => e.preventDefault()}>
                    <div className={classes.name}>
                        <span>
                            {t('payments.information.sum.partial')} <br />
                        </span>
                        <span>
                            {formatNumberPrice(paymentSum)}
                        </span>
                    </div>
                    <div className={classes.setting + ' space-y-4 lg:space-y-0'}>
                        <div className={classes.inputCount}>
                            <input
                                defaultValue={paymentSum}
                                type="number"
                                min={minSum}
                                step={1}
                                id='partialSum'
                                max={maxSum}
                                onChange={onChangeSum}
                                onBlur={onBlur}
                                value={paymentSum}
                            />
                        </div>
                    </div>
                </form>
            ) : null}
            {deposit > 0 && deposit_payment && !hideDepositCompanies.includes(company_id) &&
                (
                    <form className={classes.option} onSubmit={depositPayment}>
                        <div className={classes.name}>
                            <span>
                                {t('payments.information.deposit.title.remains')} <br /> {t('payments.information.deposit.title.deposit')}
                            </span>
                            <span>
                                {formatNumberPrice(deposit)}
                            </span>
                        </div>
                        <div className={classes.setting + ' space-y-4 lg:space-y-0'}>
                            <div className={classes.inputCount}>
                                <input
                                    defaultValue={deposit > orderDebt ? orderDebt : deposit}
                                    type="number"
                                    min={0.1}
                                    step={0.01}
                                    id='deposit'
                                    max={deposit > orderDebt ? orderDebt : deposit}
                                    placeholder={'0'}
                                />
                            </div>
                            <div className={classes.checkbox}>
                                <button className="bg-slate-200 p-3 rounded-lg disabled:cursor-not-allowed" disabled={deposit <= 0 || isLoadingDeposit || isLoadingBonus}>
                                    {isLoadingDeposit
                                        ?
                                        t('payments.information.deposit.button.loading')
                                        :
                                        t('payments.information.deposit.button.title')
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            {bonus > 0 && bonus_payment && !hideBonusCompanies.includes(company_id) &&
                (
                    <form className={classes.option} onSubmit={bonusPayment}>
                        <div className={classes.name}>
                            <span>
                                {t('payments.information.bonus.title.bonus')} <br /> {t('payments.information.bonus.title.remains')}
                            </span>
                            <span>
                                {bonus}
                            </span>
                        </div>
                        <div className={classes.setting + ' space-y-4 lg:space-y-0'}>
                            <div className={classes.inputCount}>
                                <input
                                    defaultValue={bonus > Number(order_info.max_bonus_pay) ? Number(order_info.max_bonus_pay) : bonus}
                                    type="number"
                                    id='bonus'
                                    min={0.1}
                                    step={0.01}
                                    max={bonus > Number(order_info.max_bonus_pay) ? Number(order_info.max_bonus_pay) : bonus}
                                    placeholder={'0'}
                                />
                            </div>
                            <button className="bg-slate-200 p-3 rounded-lg" disabled={bonus <= 0 || isLoadingBonus || isLoadingDeposit}>
                                {isLoadingBonus
                                    ?
                                    t('payments.information.bonus.button.loading')
                                    :
                                    t('payments.information.bonus.button.title')
                                }
                            </button>
                        </div>
                    </form>
                )}

            <div className={classes.total}>
                <span>
                    {t('payments.information.totalPayment')}
                </span>
                <span className={classes.key}>
                    {formatNumberPrice(orderDebt)}
                </span>
            </div>

            <div className={classes.buttons}>
                {paymentMethod === 'all' && (
                    <>
                        {(qr || isDemoMode) && (
                            <>
                                <button
                                    onClick={() => SBPPay('qr')}
                                    className="button button-great"
                                    disabled={isLoadingSBPQR || isDemoMode || isLoadingBonus || isLoadingDeposit}
                                >
                                    {isLoadingSBPQR
                                        ? t('payments.information.tinkoff.button.loading')
                                        : <img src={SPB} alt="" />}
                                </button>
                                <button
                                    onClick={() => SBPPay('card')}
                                    className="button button-great"
                                    disabled={isLoadingSBPCard || isDemoMode || isLoadingBonus || isLoadingDeposit}
                                >
                                    {isLoadingSBPCard
                                        ? t('payments.information.tinkoff.button.loading')
                                        : t('payments.information.tinkoff.button.cardTitle')}
                                </button>
                            </>
                        )}
                        {(CLinks.isUseCloudPayments() || (company_id === 118)) && (
                            <button
                                onClick={cardPayment}
                                disabled={isLoadingCard || isDemoMode || isLoadingBonus || isLoadingDeposit}
                                className="button button-great"
                            >
                                {isLoadingCard
                                    ? t('payments.information.cloudpayment.button.loading')
                                    : t('payments.information.cloudpayment.button.title')}
                            </button>
                        )}
                    </>
                )}

                {paymentMethod === 'qr' && (
                    <button
                        onClick={() => SBPPay('qr')}
                        className="button button-great"
                        disabled={isLoadingSBPQR || isDemoMode || isLoadingBonus || isLoadingDeposit}
                    >
                        {isLoadingSBPQR
                            ? t('payments.information.tinkoff.button.loading')
                            : <img src={SPB} alt="" />}
                    </button>
                )}

                {paymentMethod === 'bank' && (
                    <button
                        onClick={() => SBPPay('card')}
                        className="button button-great"
                        disabled={isLoadingSBPCard || isDemoMode || isLoadingBonus || isLoadingDeposit}
                    >
                        {isLoadingSBPCard
                            ? t('payments.information.tinkoff.button.loading')
                            : t('payments.information.tinkoff.button.cardTitle')}
                    </button>
                )}

                {general_pay && !CLinks.isUseCloudPayments() || (company_id === 118) ? (
                    Array.isArray(general_pay) ? (
                        general_pay.map((item, index) => (
                            <PaymentButton
                                key={index}
                                disabled={isDemoMode || isLoadingBonus || isLoadingDeposit}
                                generalPayHash={item}
                                paymentSum={paymentSum}
                            />
                        ))) : (
                        <PaymentButton
                            disabled={isDemoMode || isLoadingBonus || isLoadingDeposit}
                            generalPayHash={general_pay}
                            paymentSum={paymentSum}
                        />
                    )
                ) : null}
            </div>
        </div>
    );
};

export default observer(Payment);
